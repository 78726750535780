.adopted-pet{
    display: inline-block;
    width: 500px;
    height: 500px;
    padding: 30px;
}

.adopted-pet-pic{
    width: 300px;
    height: 300px;
    object-fit: contain;
}