.App {
  text-align: center;
}

.nav{
  text-align: center;
  margin-top: 10px;
}

.nav a{
  text-decoration: none;
  padding: 10px;
  margin: 10px;
}