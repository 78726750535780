.user {
    display: inline-block;
    padding: 10px;
    border: 1px solid black;
}

.active-user{
    background-color: pink;
}

.userSection{
    padding: 10px;
}