.adoptable-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;
}

.information-container{
    height: 500px;
}